<template>
	<div class="history">
		<el-card class="box-card father-card" shadow="never">
			<!-- 按钮区域 -->
			<el-row class="btns">
				<el-form ref="form" :model="form" inline>
					<!-- 搜索框 -->
					<el-form-item label="订单编号">
						<el-input v-model="form.orderNo" placeholder="请输入订单编号" clearable></el-input>
					</el-form-item>
					<el-form-item label="订单类型">
						<el-select v-model="form.applicationCode" placeholder="请选择订单类型" filterable clearable>
							<el-option label="平台" value="10000"></el-option>
							<el-option label="小程序" value="10001"></el-option>
							<el-option label="APP" value="10002"></el-option>
							<el-option label="健康卡" value="10003"></el-option>
                            <el-option label="公众号H5" value="10008"></el-option>
							<el-option label="优医指南" value="10004"></el-option>

						</el-select>
					</el-form-item>
					<!-- 按钮区域 -->
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" @click="getAllClient()">搜索</el-button>
                        <el-button type="primary" @click="pageorderPharmacy()">添加设备费用</el-button>
						<!-- <el-button type="primary" icon="el-icon-plus" @click="add()">添加</el-button>
            <el-button type="primary" icon="el-icon-edit" @click="edit()">修改</el-button>
            <el-button type="primary" icon="el-icon-delete-solid" @click="del()">删除</el-button>-->
						<!-- <el-button type="primary" icon="el-icon-search">重置密码</el-button> -->
					</el-form-item>
				</el-form>
			</el-row>
			<el-row class="tab">
				<!-- 表格区域 -->
				<el-table ref="multipleTable" :data="clientList" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowClass">
					<el-table-column prop="orderNo" label="编号" align="center"></el-table-column>
					<el-table-column prop="orderCompanyName" label="所属机构" align="center"></el-table-column>
                    <el-table-column prop="carNum" label="车牌号" align="center"></el-table-column>
					<el-table-column prop="memberName" label="用户姓名" align="center" width="100px"></el-table-column>
					<el-table-column prop="memberMobile" label="联系电话" align="center"></el-table-column>
                    <el-table-column prop="orderType" label="订单类型" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.orderType === '0'">{{ "立即用车" }}</div>
                            <div v-else>
                                <!-- {{ "预约用车" }} -->
                                {{ scope.row.orderType === "0" ? "" : scope.row.orderReserveDate }}
                            </div>
                        </template>
                    </el-table-column>
					<el-table-column prop="startAddress" label="出发地" align="center"></el-table-column>
					<el-table-column prop="endAddress" label="目的地" align="center"></el-table-column>
					<el-table-column prop="transferVehicle" label="转运类型" align="center"></el-table-column>
					<el-table-column prop="createTime" label="下单时间" align="center"></el-table-column>
					<el-table-column prop="applicationCode" label="订单来源" align="center" width="80px">
						<template slot-scope="scope">
							<span effect="dark" type="success" v-if="scope.row.applicationCode==='10001'">{{'小程序'}}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.applicationCode==='10000'">
								{{'平台'}}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.applicationCode==='10002'">
								{{'APP'}}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.applicationCode==='10003'">
								{{'健康卡'}}
							</span>
                            <span effect="dark" type="success" v-else-if="scope.row.applicationCode==='10008'">
								{{'公众号H5'}}
							</span>
							<span effect="dark" type="success" v-else>{{'优医指南'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="status" label="状态" align="center" width="100px">
						<template slot-scope="scope">
							<span effect="dark" v-if="scope.row.status === '0'">{{ "新增" }}</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '1'">{{ "已派单" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '2'">{{ "已接单" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '3'">{{ "变更" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '4'">{{ "已支付" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '5'">{{ "取消订单" }}
							</span>
							<span effect="dark" type="primary" v-else-if="scope.row.status === '6'">{{ "全额退款" }}
							</span>
							<span effect="dark" type="info" v-else-if="scope.row.status === '7'">{{ "完成" }}</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '8'">{{ "代付预付费" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '9'">{{ "到达出发地" }}
							</span>
							<span effect="dark" type="success" v-else-if="scope.row.status === '10'">{{ "到达目的地" }}
							</span>
							<span effect="dark" type="warning" v-else-if="scope.row.status === '11'">{{ "非正常完成" }}
							</span>
							<span effect="dark" type="danger" v-else-if="scope.row.status === '12'">{{ "已退预付" }}
							</span>
							<span effect="dark" type="danger" v-else-if="scope.row.status === '13'">{{ "已退结单" }}
							</span>
						</template>
					</el-table-column>
					<el-table-column label="详情" align="center" min-width="280px">
						<template slot-scope="scope">
							<el-button size="mini" @click="look(scope.row)" type="primary">查看详情</el-button>
							<el-button size="mini" @click="edit(scope.row)" type="primary">编辑</el-button>
							<el-popconfirm confirm-button-text='确定' cancel-button-text='不用了' icon="el-icon-info"
								icon-color="red" title="确定要取消该订单吗？" @confirm="refund(scope.row)">
								<el-button slot="reference" type="danger" icon="el-icon-delete" size="mini"
									style="margin-left: 5px;">取消</el-button>
							</el-popconfirm>
							<el-popconfirm confirm-button-text='确定' cancel-button-text='不用了' icon="el-icon-info"
								icon-color="red" title="确定要撤销分配该订单吗？" @confirm="cancelAllot(scope.row)">
								<el-button slot="reference" icon="el-icon-warning-outline" type="warning" size="mini"
									style="margin-left: 5px;">撤销分配
								</el-button>
							</el-popconfirm>
							<el-popconfirm confirm-button-text='确定' cancel-button-text='不用了' icon="el-icon-info"
								icon-color="red" title="确定要提前完成该订单吗？" @confirm="ahead(scope.row)">
								<el-button slot="reference" type="warning" icon="el-icon-warning-outline" size="mini"
									style="margin-left: 5px;">提前完成</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页区域 -->
				<el-pagination @size-change="handleSizeChange" @current-change="handlePageNumChange"
					:current-page="paging.pageNo" :page-sizes="[5, 10, 20, 50]" :page-size="paging.pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
			</el-row>
			<!-- 地图选点 -->
			<el-dialog :visible.sync="mapVisible" class="propertyDialog" :close-on-click-modal="false"
				@close="iframeDialogClose()">
				<div class="digLogCont">
					<iframe id="mapPage" width="100%" height="100%" frameborder="0"
						src="https://mapapi.qq.com/web/mapComponents/locationPicker/v/index.html?search=1&type=1&key=G7BBZ-UP7LU-S3KVH-BCBOP-KZZZO-PABAO&referer=myapp&policy=1"></iframe>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="mapVisible=false">取消</el-button>
					<el-button type="primary" @click="confirmMapAddress()">确认</el-button>
				</div>
			</el-dialog>
			<!-- 订单编辑弹出框 -->
			<el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog" @close="DiaClose">
				<span slot="title" class="dialog-Title">调度历史详情-编辑</span>
				<el-form :model="orderForm" inline ref="orderForm">
					<div class="fpddcolor">
						<el-row class="subtitle">客户资料</el-row>
						<!-- 第一行 -->
						<div style="background-color: #fff;display: flex;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									客户姓名</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.memberName}}</div>
							</div>
							<div class="cflex">
								<div
									style="background-color: #F3F6FA;padding: 10px 20px;width: 100px;text-align: center;">
									联系电话</div>
								<div style="padding: 10px 20px;">{{orderForm.memberMobile}}</div>
							</div>
						</div>
						<el-row class="subtitle">车辆信息</el-row>
						<!-- 第二行 -->
						<el-row>
							<!-- <el-form-item label="车牌号">
              <el-select
                v-model="orderForm.carNum"
                placeholder="请选择车牌号"
                filterable
                :disabled="isDisable"
              >
                <el-option
                  :label="item.carNum"
                  :value="item.carNum"
                  v-for="(item, index) in carNumber"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item> -->
							<el-popover placement="bottom-start" v-model="isVerification.carNum.is" trigger="click"
								class="dy-popover" disabled>
								<div class="dy-content">
									<div class="content-head">
										<div class="head-input">
											<span>{{isVerification.carNum.title}}:&nbsp;&nbsp;</span>
											<el-input v-model="isVerification.carNum.input.carNum" placeholder="请输入车牌号码"
												size="mini"></el-input>
										</div>
										<el-button type="primary" size="mini"
											@click="searchCarNum(isVerification.carNum)">搜索</el-button>
										<el-button type="info" size="mini" @click="clrCarNum()">重置</el-button>
									</div>
									<div class="content-table">
										<el-table :data="isVerification.carNum.tableData" height="300" border
											style="width: 100%">
											<el-table-column prop="companyName" label="机构名称" width="150">
											</el-table-column>
											<el-table-column prop="carNum" label="车牌号码" width="100"></el-table-column>
											<el-table-column label="操作" width="60">
												<template slot-scope="scope">
													<el-button type="text" size="small" @click="selCarNum(scope.row)">确认
													</el-button>
												</template>
											</el-table-column>
										</el-table>
									</div>
								</div>
								<div class="dy-sel" slot="reference" @click="searchCarNum(isVerification.carNum)">
									<div class="sel-title"><span class="title-icon"
											v-if="isVerification.carNum.isMandatory">*</span><span
											class="title-text">{{isVerification.carNum.title}}</span></div>
									<div class="sel-input" :class="isVerification.carNum.text ? '' : 'dycolor'">
										{{isVerification.carNum.text ? isVerification.carNum.text : (orderForm.carNum ? orderForm.carNum  : '请选择车牌号码')}}
									</div>
								</div>
							</el-popover>
							<el-popover placement="bottom-start" v-model="isVerification.driverName.is" trigger="click"
								class="dy-popover" disabled>
								<div class="dy-content">
									<div class="content-head">
										<div class="head-input">
											<span>{{isVerification.driverName.title}}:&nbsp;&nbsp;</span>
											<el-input v-model="isVerification.driverName.input.driverName"
												placeholder="请输入车牌号码" size="mini"></el-input>
										</div>
										<el-button type="primary" size="mini"
											@click="searchDriverName(isVerification.driverName)">搜索</el-button>
										<el-button type="info" size="mini" @click="clrDriverName()">重置</el-button>
									</div>
									<div class="content-table">
										<el-table :data="isVerification.driverName.tableData" height="300" border
											style="width: 100%">
											<el-table-column prop="companyName" label="机构名称" width="150">
											</el-table-column>
											<el-table-column prop="driverName" label="司机名称" width="100">
											</el-table-column>
											<el-table-column label="操作" width="60">
												<template slot-scope="scope">
													<el-button type="text" size="small"
														@click="selDriverName(scope.row)">确认</el-button>
												</template>
											</el-table-column>
										</el-table>
									</div>
								</div>
								<div class="dy-sel" slot="reference"
									@click="searchDriverName(isVerification.driverName)">
									<div class="sel-title"><span class="title-icon"
											v-if="isVerification.driverName.isMandatory">*</span><span
											class="title-text">{{isVerification.driverName.title}}</span></div>
									<div class="sel-input" :class="isVerification.driverName.text ? '' : 'dycolor'">
										{{isVerification.driverName.text ? isVerification.driverName.text : (orderForm.driverName ? orderForm.driverName : "请选择司机名称")}}
									</div>
								</div>
							</el-popover>
							<el-form-item label="转运类型">
								<el-select v-model="orderForm.transportTypeId" placeholder="请选择转运类型"
									@change="TransportTypeChange(orderForm.transportTypeId )" :disabled="isDisable">
									<el-option :label="item.transportTypeName" :value="item.uuid"
										v-for="(item, index) in TransportType" :key="index"></el-option>
								</el-select>
							</el-form-item>
						</el-row>
						<!-- 第三行 -->
						<el-row>

						</el-row>
						<!-- 第四行 -->
						<el-row>
							<el-form-item label="随行医生">
								<el-select  v-model="orderForm.doctorPersonId" placeholder="请选择随车医生" filterable clearable
									multiple :disabled="isDisable" @change="selectDoc" >
									<el-option :disabled="item.disabled" :label="item.DOC_NAME" :value="item.uuid" v-for="item in doctor"
										:key="item.uuid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="随行护士">
								<el-select v-model="orderForm.nursePersonId" placeholder="请选择随车护士" filterable clearable
									multiple :disabled="isDisable" @change="selectNurse" >
									<el-option :disabled="item.disabled" :label="item.DOC_NAME" :value="item.uuid" v-for="item in nurse"
										:key="item.uuid"></el-option>
								</el-select>
							</el-form-item>
						</el-row>
						<!-- 第五行 -->
						<el-row>
							<el-form-item label="护送人员">
								<el-select v-model="orderForm.escorts" placeholder="请选择护送人员" filterable clearable
									multiple :disabled="isDisable" @change="selectEscort" >
									<el-option :disabled="item.disabled" :label="item.DOC_NAME" :value="item.uuid" v-for="item in escorts"
										:key="item.uuid"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="搬抬人员">
								<el-select v-model="orderForm.carryPersonId" placeholder="请选择搬抬人员" filterable clearable
									multiple :disabled="isDisable" >
									<el-option :disabled="item.disabled" :label="item.DOC_NAME" :value="item.uuid" v-for="item in lifting"
										:key="item.uuid"></el-option>
								</el-select>
							</el-form-item>
						</el-row>
						<!-- 第六行 -->
						<!-- <el-row>
            <el-form-item label="出发地">
              <el-input v-model="orderForm.startAddress" autocomplete="off" disabled clearable></el-input>
            </el-form-item>
          </el-row> -->
						<div class="fpddcolor-in"
							style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									出发地</div>
								<!-- <div style="padding: 10px 20px;">{{orderForm.startAddress}}</div> -->
								<!-- <i slot="suffix"  class="el-input__icon el-icon-location-outline dw"></i> -->
                                <div class="host-name-box">
                                    <div class="orderHospName">{{orderForm.startOrderAddress}}</div>
                                    <div  v-show="isEndOrHostAddress(orderForm.startOrderAddress,orderForm.startAddress)" class="endAddress">
                                        {{orderForm.startAddress}}
                                    </div>
                                </div>
							</div>
						</div>
						<!-- 第七行 -->
						<div class="fpddcolor-in"
							style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									目的地</div>
								<div style="padding: 10px 20px;" @click="selectAddress('1')">
									<!-- {{orderForm.endAddress}} -->
                                    <div style="color:#FF7F50" class="orderHospName">{{orderForm.orderHospName}}</div>
                                    <div v-show="isEndOrHostAddress(orderForm.endAddress,orderForm.orderHospName)" class="endAddress">
                                        {{orderForm.endAddress}}
                                    </div>
								</div>
								<i slot="suffix" class="el-input__icon el-icon-location-outline dw"
									@click="selectAddress('1')"></i>
							</div>
						</div>
						<!-- <el-row>
            <el-form-item label="目的地">
              <el-input v-model="orderForm.endAddress"  autocomplete="off" placeholder="请选择目的地" @focus="selectAddress('1')" clearable>
              <i slot="suffix"  class="el-input__icon el-icon-location-outline dw"  @click="selectAddress('1')"></i>
              </el-input>
            </el-form-item>
          </el-row> -->
						<div class="fpddcolor-in"
							style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									预估金额</div>
								<div style="padding: 10px 20px;width: 200px;color: red;">{{upmoney.allMoney}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									预估预付</div>
								<div style="padding: 10px 20px;width: 200px;color: red;">{{upmoney.sumMoney}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">里程
								</div>
								<div style="padding: 10px 20px;width: 200px;color: red;">{{upmoney.predictMile}}</div>
							</div>
						</div>
						<div class="fpddcolor-in"
							style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									转运费用</div>
								<div style="padding: 10px 20px;width: 200px;color: red;">{{upmoney.transPayBase}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									搬抬费</div>
								<div style="padding: 10px 20px;width: 200px;color: red;">{{upmoney.btPay}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									里程费</div>
								<div style="padding: 10px 20px;width: 200px;color: red;">{{upmoney.milePay}}</div>
							</div>
						</div>
						<div class="fpddcolor-in" style="background-color: #fff;display: flex;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									修改议价金额</div>
								<input v-input-limit placeholder="请输入订单议价金额" v-model="upsunmoney" />
							</div>
						</div>
						<el-row class="subtitle">订单信息</el-row>
						<!-- 第八行 -->
						<div class="fpddcolor-in"
							style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									病人姓名</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.patientName}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									病人年龄</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.patientAge}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									手机号码</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.patientMobile}}</div>
							</div>
						</div>
						<!-- 第九行 -->
						<div class="fpddcolor-in"
							style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									病情描述</div>
								<div style="padding: 10px 20px;">{{orderForm.patientCondition}}</div>
							</div>
						</div>
						<!-- 第十行 -->
						<div style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									订单编号</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderNo}}</div>
							</div>
							<div class="cflex">
								<div
									style="background-color: #F3F6FA;padding: 10px 20px;width: 100px;text-align: center;">
									预估金额</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderPredictFee}}</div>
							</div>
							<div class="cflex">
								<div
									style="background-color: #F3F6FA;padding: 10px 20px;width: 100px;text-align: center;">
									订单来源</div>
								<div style="padding: 10px 20px;width: 200px;">
									<!-- {{orderForm.applicationCode == 10000 ? '平台' : orderForm.applicationCode == 10001 ? '小程序':'APP'}} -->
									<span v-if="orderForm.applicationCode == 10000">平台</span>
									<span v-else-if="orderForm.applicationCode == 10001">小程序</span>
									<span v-else-if="orderForm.applicationCode == 10002">APP</span>
									<span v-else-if="orderForm.applicationCode == 10003">健康卡</span>
                                    <span v-else-if="orderForm.applicationCode == 10008">公众号H5</span>
								</div>
							</div>

						</div>
						<div style="background-color: #fff;display: flex;">
							<div class="cflex">
								<div
									style="background-color: #F3F6FA;padding: 10px 20px;width: 100px;text-align: center;">
									下单时间</div>
								<div style="padding: 10px 20px;">{{orderForm.orderDate}}</div>
							</div>
						</div>
					</div>
				</el-form>

				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="sure()">确 认</el-button>
				</div>
			</el-dialog>
			<!-- 订单详情 -->
			<el-dialog :visible.sync="orderDialogFormq" class="order-dialog">
				<span slot="title" class="dialog-Title">调度历史详情</span>
				<el-form :model="orderForm" inline ref="orderForm">
					<div class="fpddcolor">
						<div class="daijiaocolor">
							<div class="daijiaocolor-left" style="padding-left: 10px;"><img
									src="../../images/us.png">客户信息</div>
						</div>
						<div style="background-color: #fff;display: flex;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									下单人姓名</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.memberName}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px 20px;width: 100px;">手机号码</div>
								<div style="padding: 10px 20px;">{{orderForm.memberMobile}}</div>
							</div>
						</div>
						<div>
							<div class="daijiaocolor">
								<div class="daijiaocolor-left" style="padding-left: 10px;"><img
										src="../../images/us.png">患者信息</div>
							</div>
							<div style="display: flex;background-color: #fff;border-bottom: #F3F6FA 2px solid;">
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										患者姓名</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.patientName || '暂无信息'}}
									</div>
								</div>
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										患者年龄</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.patientAge || '暂无信息'}}
									</div>
								</div>
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										患者手机号码</div>
									<div style="padding: 10px 20px;width: 200px;">{{orderForm.patientMobile || '暂无信息'}}
									</div>
								</div>
							</div>
							<div style="display: flex;background-color: #fff;">
								<div class="cflex">
									<div
										style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
										病情描述</div>
									<div style="padding: 10px 20px;">{{orderForm.patientCondition || '暂无信息'}}
									</div>
								</div>
							</div>
						</div>
						<div class="daijiaocolor">
							<div class="daijiaocolor-left" style="padding-left: 10px;"><img
									src="../../fonts/home.png">订单信息</div>
						</div>
						<div class="fpddcolor-in"
							style="display: flex;background-color: #fff;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									下单时间</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.createTime}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									订单来源</div>
								<div style="padding: 10px 20px;width: 200px;">
									<!-- {{orderForm.applicationCode == 10000 ? '平台' : orderForm.applicationCode == 10001 ? '小程序':'APP'}} -->
									<span v-if="orderForm.applicationCode == 10000">平台</span>
									<span v-else-if="orderForm.applicationCode == 10001">小程序</span>
									<span v-else-if="orderForm.applicationCode == 10002">APP</span>
									<span v-else-if="orderForm.applicationCode == 10003">健康卡</span>
                                    <span v-else-if="orderForm.applicationCode == 10008">公众号H5</span>
								</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									订单编号</div>
								<div style="padding: 10px 20px;">{{orderForm.orderNo}}</div>
							</div>
						</div>
						<div class="fpddcolor-in"
							style="display: flex;background-color: #fff;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									转运类型</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.transferVehicle}}</div>
							</div>

							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									订单类型</div>
								<!-- <div style="padding: 10px 20px;">{{orderForm.orderNo}}</div> -->
								<div style="padding: 10px 20px;width: 200px;" v-if="orderForm.orderType == 0">立即用车</div>
								<div style="padding: 10px 20px;width: 200px;" v-if="orderForm.orderType == 1">预约用车</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									实跑公里</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderFactMile}}</div>
							</div>
						</div>
						<div style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									预估金额</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderPredictFee}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									预估预付</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderPredictPay}}</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									预估公里</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.predictMile}}</div>
							</div>
						</div>
						<div style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									议价费用</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.bargainFee}}</div>
							</div>
                            <div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									搬抬费用</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.carryMoney}}</div>
							</div>
						</div>
						<div style="background-color: #fff;display: flex;border-bottom: #F3F6FA 2px solid;">

							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									已付预付</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderPredictRealityPay || 0}}
								</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									已付结单</div>
								<div style="padding: 10px 20px;width: 200px;">{{orderForm.orderFactFee || 0}}
								</div>
							</div>
							<div class="cflex">
								<div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">
									订单总付</div>
								<div style="padding: 10px 20px;width: 200px;">{{Number(orderForm.orderPredictRealityPay)+Number(orderForm.orderFactFee)}}</div>
							</div>
						</div>

						<div class="daijiaocolor">
							<div class="daijiaocolor-left" style="padding-left: 10px;"><img
									src="../../fonts/location@2x.png">车辆信息</div>
						</div>
						<div style="background-color: #fff;padding: 10px 10px 0 10px;">
							<div>
								<el-row class="dialog-Title">
									<el-form-item label="车牌号:">
										<!-- <el-input v-model="orderForm.carNum" autocomplete="off" disabled clearable></el-input> -->
										{{orderForm.carNum}}
									</el-form-item>
									<el-form-item label="司机名称:">
										<!-- <el-input v-model="orderForm.driverName" autocomplete="off" disabled clearable></el-input> -->
										{{orderForm.driverName}}
									</el-form-item>
									<el-form-item label="转运类型:">
										{{orderForm.transferVehicle}}
									</el-form-item>
									<el-form-item label="司机电话:">
										<!-- <el-input v-model="orderForm.driverPhone" autocomplete="off" disabled clearable></el-input> -->
										{{orderForm.phoneNo}}
									</el-form-item>
								</el-row>
							</div>
							<div>
								<el-row class="dialog-Title">
									<el-form-item label="随行医生:">
										{{orderForm.doctorNameList}}
									</el-form-item>
									<el-form-item label="随行护士:">
										{{orderForm.nurseNameList}}
									</el-form-item>
									<el-form-item label="护送人员:">
										{{orderForm.escortNameList}}
									</el-form-item>
									<el-form-item label="搬抬人员:">
										{{orderForm.raiseNameList}}
									</el-form-item>
								</el-row>
							</div>
						</div>

						<div class="daijiaocolor">
							<div class="daijiaocolor-left" style="padding-left: 10px;"><img
									src="../../fonts/location@2x.png">位置信息</div>
						</div>
						<div class="fpddcolor-low" style="background-color: #fff;padding: 10px;">
							<div style="color:#0097fe;display: flex;align-items: center;">
                                <img
									src="../../fonts/operation@3x.png" style="margin-right: 10px;">
								<div style="width: 60px;">起始地:</div>
								<!-- <div>{{orderForm.startAddress}}</div> -->
                                 <div class="host-name-box">
                                    <div class="orderHospName">{{orderForm.startOrderAddress}}</div>
                                    <div  v-show="isEndOrHostAddress(orderForm.startOrderAddress,orderForm.startAddress)" class="endAddress">
                                        {{orderForm.startAddress}}
                                    </div>
                                </div>
							</div>
							<div style="color:#FF7F50;display: flex;align-items: center;margin-top: 10px;"><img
									src="../../images/zd.png" style="margin-right: 10px;">
								<div style="width: 60px;">目的地:</div>
								<div class="host-name-box">
                                    <div class="orderHospName">{{orderForm.orderHospName}}</div>
                                    <div v-show="isEndOrHostAddress(orderForm.endAddress,orderForm.orderHospName)" class="endAddress">
                                        {{orderForm.endAddress}}
                                    </div>
                                </div>
							</div>
						</div>
					</div>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="orderDialogFormq=false">关 闭</el-button>
				</div>
			</el-dialog>

		</el-card>
         <BaiDuMap ref="bmapAddressSelect" @confirmMapAddress="confirmMapAddress"></BaiDuMap>

	</div>
</template>

<script>
import { isEndOrHostAddress } from '@/utils/addressMet'
import BaiDuMap from '@/components/Baidu-map.vue'

export default {
  name: 'history',
  components: {
    BaiDuMap
  },
  data () {
    return {
      isEndOrHostAddress: isEndOrHostAddress,
      clientList: [], // 订单列表
      Selected: false, // 是否选择表格对象
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 10, // 总的数据条数
      props: {
        label: 'province',
        value: 'uuid',
        children: 'children'
      },
      form: {
        orderNo: '',
        applicationCode: ''
      },
      upmoney: {

      },
      orderForm: {

      }, // 订单详情列表
      orderDialogFormVisible: false,
      orderDialogFormq: false,
      editDialogFormVisible: false, // 控制编辑弹出框的显示与隐藏
      isDisable: true, // 控制订单是否能被编辑
      carNumber: '', // 车牌号
      moneyForm: {}, // 费用表单
      driverNumber: [], // 司机
      doctor: [], // 医生
      nurse: [], // 护士
      upsunmoney: '', // 修改后的总额
      lifting: [], // 搬抬人员
      escorts: [], // 护送人员
      mapVisible: false,
      TransportType: [],
      da: '', // 判断输入起点还是终点
      adress: {
        a: '', // 详细位置
        b: '', // 经纬度
        c: '' // 大概地址
      }, // 起点信息
      adressed: {
        a: '',
        b: ''
      }, // 终点信息
      isVerification: {
        carNum: {
          title: '车牌号码',
          text: '',
          isMandatory: true,
          is: false,
          input: {
            carNum: ''
          },
          data: null,
          tableData: []
        },
        driverName: {
          title: '司机名称',
          text: '',
          isMandatory: true,
          is: false,
          input: {
            driverName: ''
          },
          data: null,
          tableData: []
        }
      },
      isModfiyEndArress: false
    }
  },
  mounted () {
    window.latlng = {
      endAddress: '',
      startAddress: ''
    }
  },
  created () {
    this.getAllClient() // 获取所有已分配订单
    // this.search()
    this.getAllCar()
  },
  methods: {
    pageorderPharmacy () {
      this.$router.push('/system/orderlist/orderPharmacy')
    },
    // 点击起始地选择时触发
    selectAddress (v) {
      this.da = v
      //   this.mapVisible = true
      this.$refs.bmapAddressSelect.show()
    //   window.addEventListener('message', this.abc, false)
    //   console.log(window.addEventListener('message', this.abc, false), 9999)
    },
    /** 确认地图地址 */
    confirmMapAddress (addressInfo) {
      console.log(addressInfo) // 获取到选点的地址和经纬度
      const { latitude, longitude, province, district, address, poiaddress } = addressInfo
      const latlng = {
        lat: latitude,
        lng: longitude
      }
      if (this.da === '1') {
        // this.addForm.endAddress = province + district + address
        // window.latlng.endAddress = latlng
        this.orderForm.orderHospName = province + district + address
        this.orderForm.endAddress = poiaddress
        window.latlng.endAddress = latlng
        this.orderForm.endLatitude = latlng.lat
        this.orderForm.endLongitude = latlng.lng
        this.isModfiyEndArress = true// 修改目的地
        this.countMoney()
      } else {
        // this.addForm.startAddress = province + district + address
        // window.latlng.startAddress = latlng
        // this.countMoney()

        this.orderForm.startAddress = province + district + address
        window.latlng.startAddress = latlng
        this.countMoney()
      }

      this.$forceUpdate()
      this.mapVisible = false
    },
    abc (event) {
      var that = this
      // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
      const mapData = event.data
      if (mapData && mapData.module == 'locationPicker') {
        that.mapData = mapData
      }
      console.log(event)
      if (this.da === '1') {
        console.log(event.data, 'event.data')
        this.adress.a = event.data.poiaddress
        this.adress.c = event.data.poiname
        this.adress.b = event.data.latlng
        // console.log('终点', this.adress.a);
        // that.addForm.endAddress = event.data.poiname
        // window.latlng.endAddress = event.data.latlng
      } else {
        this.adressed.a = event.data.poiaddress + event.data.poiname
        this.adressed.b = event.data.latlng
        // console.log('起点', this.adressed.a);
        // that.addForm.startAddress = event.data.poiname
        // window.latlng.startAddress = event.data.latlng
      }
    },
    // 地图选点确认按钮
    confirmMapData () {
      console.log(this.adress.b, 'this.da')
      if (this.da === '1') {
        this.orderForm.orderHospName = this.adress.c
        this.orderForm.endAddress = this.adress.a
        window.latlng.endAddress = this.adress.b
        this.orderForm.endLatitude = this.adress.b.lat
        this.orderForm.endLongitude = this.adress.b.lng
        this.isModfiyEndArress = true// 修改目的地
        this.countMoney()
      } else {
        this.orderForm.startAddress = this.adressed.a
        window.latlng.startAddress = this.adressed.b
        this.countMoney()
      }
      this.mapVisible = false
    },
    // 监听地图选点的关闭事件
    iframeDialogClose () {
      window.removeEventListener('message', this.abc, false)
      this.adress = {
        a: '',
        b: ''
      }
      this.adressed = {
        a: '',
        b: ''
      }
    },
    async countMoney (lat = window.latlng.endAddress.lat, lng = window.latlng.endAddress.lng) {
      if (!lat) {
        return false
      }
      const {
        data: res
      } = await this.$http.post('/userManageServer/pay/selectTransportMap', {
        form: this.orderForm.startLatitude + ',' + this.orderForm.startLongitude,
        to: lat + ',' + lng,
        transportTypeId: this.orderForm.transportTypeId,
        tstretcherCount: this.orderForm.stretcherPersonNum
      })

      this.upmoney = res.data
      console.log(this.upmoney)
      // this.moneyForm = res.data
      // if (this.moneyForm.predictMile < 0) {
      //   this.moneyForm.predictMile = 0
      // }
    },
    // 车牌号码搜索方法
    async searchCarNum (data) {
      const {
        data: res
      } = await this.$http.post('/userManageServer/driver/selectDriverNameAndCarNumList', {
        carNum: data.input.carNum
        // "status": 1
      })
      this.isVerification.carNum.tableData = res.data.CarNumList
    },
    selCarNum (data) {
      console.log(data)
      this.isVerification.carNum.text = data.carNum
      this.isVerification.carNum.is = false
    },
    clrCarNum () {
      this.isVerification.carNum.input.carNum = ''
      this.isVerification.carNum.text = ''
      this.searchCarNum(this.isVerification.carNum)
    },
    // 司机名称搜索方法
    async searchDriverName (data) {
      const {
        data: res
      } = await this.$http.post('/userManageServer/driver/selectDriverNameAndCarNumList', {
        driverName: data.input.driverName
        // "status": 1
      })
      this.isVerification.driverName.tableData = res.data.DriverNameList
    },
    selDriverName (data) {
      this.isVerification.driverName.text = data.driverName
      this.isVerification.driverName.id = data.id
      this.isVerification.driverName.is = false
    },
    clrDriverName () {
      this.isVerification.driverName.input.driverName = ''
      this.isVerification.driverName.text = ''
      this.searchDriverName(this.isVerification.driverName)
    },
    // 获取所有订单列表--调试完成
    // /userManageServer/pay/selectOrderList
    async getAllClient () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/pay/selectOrderHistoryDispatchList', {
        // code: '1',
        // "status": "1",
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        applicationCode: this.form.applicationCode
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')
      this.clientList = res.data
      this.total = +res.pageBean.pageDataCount
    },
    // 给表格头部设定背景色
    rowClass ({
      row,
      rowIndex
    }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    async handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      await this.getAllClient()
    },
    // 切换页码时触发
    async handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      await this.getAllClient()
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      if (this.form.orderNo === '' && this.form.applicationCode === '') return this.$message.error('请输入搜索内容')
      // 调接口，进行查询
      const {
        data: res
      } = await this.$http.post('/userManageServer/pay/selectOrderList', {
        status: 1,
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        orderNo: this.form.orderNo,
        applicationCode: this.form.applicationCode
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      this.clientList = res.data
      this.total = +res.pageBean.pageDataCount
    },
    // 点击查看详情按钮触发
    look (v) {
      // v.doctorList = v.doctorList.toString()
      // v.nurseList = v.nurseList.toString()
      // v.escortList = v.escortList.toString()
      // v.raiseList = v.raiseList.toString()
      // this.isDisable = true
      this.orderForm = JSON.parse(JSON.stringify(v))
      this.getAllCarNumber(v)
      // this.orderDialogFormVisible = true
      this.orderDialogFormq = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isModfiyEndArress = false// 编辑弹窗出现时默认没修改地址
      this.isDisable = false
      this.orderForm = JSON.parse(JSON.stringify(v))
      this.upmoney = {}
      this.upsunmoney = this.orderForm.bargainFee
      console.log(v)
      this.getAllCarNumber(this.orderForm)
      this.getAlldriverNumber(this.orderForm)
      this.getAlldoctor(this.orderForm)
      this.getAllnurse(this.orderForm)
      this.getAlllifting(this.orderForm)
      this.getAllEscorts(this.orderForm)

      this.orderDialogFormVisible = true
    },
    // 点击提前完成按钮触发
    async ahead (v) {
      console.log(v)
      if (v.applicationCode == '10004') return this.$message.error('暂不支持取消')
      const {
        data: res
      } = await this.$http.post('/userManageServer/pay/updateOrderStatusInAdvance', {
        orderNo: v.orderNo
      })
      if (res.statusCode !== '200') return this.$message.error('提前完成失败')
      // 成功提示
      this.$message.success('提前完成成功')
      this.getAllClient()
    },
    // 取消订单
    async refund (v) {
      if (v.applicationCode == '10004') return this.$message.error('暂不支持取消')
      const {
        data: res
      } = await this.$http.post('/userManageServer/pay/addOrderCance', {
        orderNo: v.orderNo,
        createBy: sessionStorage.getItem('userId'),
        cancelReason: '调度取消订单'
      })
      if (res.statusCode !== '200') return this.$message.error('取消订单失败')
      // 成功提示
      this.$message.success('取消订单成功')
      this.getAllClient()
    },
    // 点击确认按钮触发 -编辑修改订单
    sure () {
      this.$confirm('确定数据是否填写正确？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (this.isVerification.carNum.text != '') {
          this.orderForm.carNum = this.isVerification.carNum.text
        }
        console.log(this.orderForm, window.latlng.endAddress, 'window.latlng.endAddress')
        if (window.latlng.endAddress != '') {
          this.orderForm.endLatitude = window.latlng.endAddress.lat
          this.orderForm.endLongitude = window.latlng.endAddress.lng
        // this.orderForm.endPoiName = window.poiname
        // console.log( window.latlng.endAddress)
        }
        if (this.isVerification.driverName.id != '') {
          this.orderForm.carPersonId = this.isVerification.driverName.id
        }

        this.orderForm.bargainMoney = this.upsunmoney
        this.orderForm.userId = sessionStorage.getItem('userId')
        const obj = JSON.parse(JSON.stringify(this.orderForm))
        if (!this.isModfiyEndArress) {
          delete obj.orderHospName
          delete obj.endAddress
          delete obj.endLatitude
          delete obj.endLongitude
          delete obj.startLatitude
          delete obj.startLongitude
          delete obj.startAddress
          delete obj.startOrderAddress
        }
        console.log(obj, 'obj')
        const {
          data: res
        } = await this.$http.post('/userManageServer/dispatch/updateOrderDispatch', JSON.parse(JSON.stringify(
          obj)))
        // console.log(res);
        if (res.statusCode !== '200') return this.$message.error('修改失败')
        this.$message.success('修改成功')
        this.getAllClient()
        this.orderDialogFormVisible = false
      })
    },
    // 获取车牌号--调试完成
    async getAllCarNumber (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoVehicle/getTInfoVehicleList', {
        corpId: v.orderCompany,
        onStatus: '1'
      })
      if (res.statusCode !== '200') return this.$message.error('获取车辆列表失败')
      this.carNumber = res.data[0].carNum
      // this.orderForm.carNum = res.data[0].carNum;
    },
    // 获取司机--调试完成
    async getAlldriverNumber (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDriver/getTInfoDriverList', {
        companyId: v.orderCompany,
        onStatus: '1'
      })
      // console.log(res);
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取司机失败')
      this.driverNumber = res.data.records
    },
    // 获取随车医生--调试完成
    async getAlldoctor (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.orderCompany,
        docType: 0
        // onStatus: '1'
      })
      if (res.statusCode !== '200') return this.$message.error('获取医生列表失败')
      this.doctor = res.data.records
      this.doctor.forEach(item => {
        if (item.onStatus != 1) {
          this.$set(item, 'disabled', true)
        } else {
          this.$set(item, 'disabled', false)
        }
        if (this.orderForm.doctorPersonId.includes(item.uuid)) {
          this.$set(item, 'disabled', false)
        }
      })
    },
    // 获取随车护士--调试完成
    async getAllnurse (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.orderCompany,
        docType: 1
        // onStatus: '1'
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取随车护士失败')
      this.nurse = res.data.records
      this.nurse.forEach(item => {
        if (item.onStatus != 1) {
          this.$set(item, 'disabled', true)
        } else {
          this.$set(item, 'disabled', false)
        }
        if (this.orderForm.nursePersonId.includes(item.uuid)) {
          this.$set(item, 'disabled', false)
        }
      })
    },
    // 获取所有搬抬人员--调试完成
    async getAlllifting (v) {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.orderCompany,
        docType: 3
        // onStatus: '1'
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取搬抬人员失败')
      this.lifting = res.data.records
      this.lifting.forEach(item => {
        if (item.onStatus != 1) {
          this.$set(item, 'disabled', true)
        } else {
          this.$set(item, 'disabled', false)
        }
        if (this.orderForm.carryPersonId.includes(item.uuid)) {
          this.$set(item, 'disabled', false)
        }
      })
    },
    // 获取所有护送员--调试完成
    async getAllEscorts (v) {
      // console.log(this.allotForm.orderCompany);
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoDoctor/getTInfoDoctorList', {
        companyId: v.orderCompany,
        docType: 2
        // onStatus: '1'
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取护送员失败')
      this.escorts = res.data.records
      this.escorts.forEach(item => {
        if (item.onStatus != 1) {
          this.$set(item, 'disabled', true)
        } else {
          this.$set(item, 'disabled', false)
        }
        if (this.orderForm.escorts.includes(item.uuid)) {
          this.$set(item, 'disabled', false)
        }
      })
    },
    // 获取所有转运类型
    async getAllCar () {
      const {
        data: res
      } = await this.$http.post('/pmService/tInfoTransportType/getTInfoTransportTypeList', {
        status: 1
      })
      if (res.statusCode !== '200') return this.$message.error('获取转运类型列表失败')
      console.log(res.data.records)
      this.TransportType = res.data.records
    },
    // 监听转运类型的change事件
    TransportTypeChange (v) {
      var that = this
      switch (v) {
        case 1:
          that.orderForm.nursePersonId = []
          that.orderForm.doctorPersonId = []
          that.orderForm.escorts = []
          break
        case 2:
          that.orderForm.nursePersonId = []
          that.orderForm.doctorPersonId = []

          break
        case 3:
          that.orderForm.doctorPersonId = []
          that.orderForm.escorts = []

          break
        case 4:
          that.orderForm.escorts = []

          break
      }
      console.log(window.latlng.endAddress)
      this.isModfiyEndArress = true// 修改目的地
      this.countMoney(this.orderForm.endLatitude, this.orderForm.endLongitude)
    },
    // 监听护士的change事件
    selectNurse () {
      if (this.orderForm.transportTypeId !== 4 && this.orderForm.transportTypeId !== 3) {
        this.orderForm.nursePersonId = []
        this.$message.warning('请选择转运类型---护理护送/监护护送')
      }
    },
    selectEscort (v) {
      if (this.orderForm.transportTypeId !== 2) {
        this.orderForm.escorts = []
        this.$message.warning('请选择转运类型---高级护送')
      }
    },
    selectDoc (v) {
      console.log(this.orderForm, 'this.orderForm')
      if (this.orderForm.transportTypeId !== 4) {
        this.orderForm.doctorPersonId = []
        this.$message.warning('请选择转运类型---监护护送')
      }
    },
    DiaClose () {
      this.orderForm = {}
    },
    async cancelAllot (v) {
      console.log(v)
      const {
        data: res
      } = await this.$http.post('/userManageServer/dispatch/cancelOrderDispatch', {
        orderNo: v.orderNo
      })
      if (res.statusCode !== '200') return this.$message.error('撤销分配失败')
      // 成功提示
      this.$message.success('撤销分配成功')
      this.getAllClient()
    }
  }
}
</script>

<style lang="less" scoped>
	.history {
		width: 100%;
		height: 100%;

		.father-card {
			.btns {
				margin-bottom: 20px;
			}

			/deep/ .current-row>td {
				background-color: #66b1ff !important;
			}

			.tab {
				.el-pagination {
					margin-bottom: 0.25rem;
					margin-top: 0.25rem;
					margin-right: 0.25rem;
					text-align: right;
				}
			}

			.propertyDialog {
				// position: absolute;
				// top: 50%;
				// left: 50%;
				// transform: translate(-50%, -50%);
				// width: 50%;
				// height: 100%;
				// margin: 0 !important;

				.el-dialog {
					// width: 100%;
					// height: 100%;
					// margin: 0 !important;
					// padding: 0;

					// display: flex;
					// flex-direction: column;
					// align-items: center;
					.el-dialog__header {
						height: 0 !important;
						width: 0 !important;

						.el-dialog__headerbtn {
							display: none;
						}
					}

					.el-dialog__body {
						padding: 0 20px;

						// height: 700px;
						.digLogCont {
							width: 100%;
							height: 700px;
						}
					}

					.el-dialog__footer {
						padding: 0 20px;
					}
				}
			}

			.order-dialog {

				// background-color: #F3F6FA;
				.dialog-Title {
					color: #0097fe;
					font-size: 20px;
					font-weight: 700;
				}

				/deep/ .el-form {
					.subtitle {
						color: #0097fe;
						font-size: 14px;
						font-weight: 700;
						margin-bottom: 20px;
						padding-left: 6px;
						border-left: 5px solid #0097fe;
					}

					.fpddcolor {
						background-color: #F3F6FA;
						padding: 10px 10px;
						// line-height: 30px;
						font-weight: bold;
						border-top-left-radius: 12px;
						border-top-right-radius: 12px;

						.fpddcolor-title {
							border-bottom: 1px solid #666666;
							font-size: 18px;
							padding-bottom: 10px;
						}

						.fpddcolor-top {
							display: flex;
							justify-content: space-between;
							// margin-top: 10px;
							font-size: 18px;
						}

						// .cflex{
						//     display: flex;
						// }
						.fpddcolor-in {
							font-size: 16px;
						}

						.fpddcolor-low {
							font-size: 18px;
							padding-bottom: 10px;

							img {
								width: 20px;
								height: 20px;
							}
						}
					}

					.daijiaocolor {
						display: flex;
						font-size: 18px;
						height: 45px;
						line-height: 45px;
						justify-content: space-between;

						.daijiaocolor-left {
							margin-right: 20px;
						}

						img {
							width: 20px;
							height: 20px;
							margin-right: 12px;
						}

						input {
							height: 45px;
							width: 300px;
							border-top: 0px;
							border-left: 0px;
							border-right: 0px;
						}
					}

					.cflex {
						display: flex;

						input {
							outline: none;
							border: 0px;
							width: 500px;
							padding-left: 20px;
							font-weight: 700;
						}
					}

					.el-row {
						display: flex;

						.el-form-item {
							flex: 1;
							display: flex;

							.el-form-item__content {
								flex: 1;

								.el-input {
									width: 100%;

									.el-input__inner {
										width: 100%;
									}
								}

								.el-select {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}

	.dy-sel {
		display: inline-block;
		align-items: center;
		margin: 0 0.125rem 0.275rem 0;

		>div {
			display: inline-block;
		}

		.sel-title {
			font-size: 0.175rem;
			margin-right: 0.15rem;

			.title-icon {
				color: #F56C6C;
				margin-right: 0.05rem;
			}
		}

		.sel-input {
			width: 2.87rem;
			height: 0.5rem;
			border-radius: 0.05rem;
			border: 0.0125rem solid #DCDFE6;
			box-sizing: border-box;
			padding: 0 0.1875rem;
			line-height: 0.5rem;
		}

	}

	.dy-content {
		width: 100%;

		.content-head {
			height: 0.5rem;
			// background-color: pink;
			display: flex;
			align-items: center;

			.head-input {
				display: flex;
				align-items: center;
				margin-right: 0.1rem;

				span {
					white-space: nowrap;
					margin-right: 0.05rem;
				}

				.el-input {
					width: 1.5rem;
				}
			}
		}

		.content-table {
			margin-top: 0.2rem;
		}
	}

	.dycolor {
		color: #DCDFE6 !important;
	}
    .orderHospName{
        // font-size:22px;
    }
    .endAddress{
        // font-size: 10px;
        color: #666666;
    }
    .host-name-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 20px;
    }
</style>
